.footer {
  padding: 1.25rem .25rem;
  background-color: black;
  color: white;
}

.footer strong {
  font-weight: 600;
}

.footer a {
  color: white;
  text-decoration: underline;
}

.brandLink {
  text-decoration: none !important;
}
