.buttonWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
  border: none;
  background: none;
  font-size: .9rem;
  line-height: 1;
  outline: none !important;
}
.button:hover {
  background-color: #e5e5e5;
}
.button.active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2) inset;
  background-color: #d3d3d3;
}

.icon {
  width: 2rem;
  margin-left: auto;
  margin-right: auto;
}
.cropTitle {
  display: none;
}

@media (min-width: 768px) {
  .buttonGrid {
    gap: 1rem;
  }
  .videoSection {
    height: 100vh;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .cropTitle {
    display: block;
    margin: 5px;
    text-align: center;
  }
  .icon {
    width: 45%;
    max-width: 2rem;
  }
}
