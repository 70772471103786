.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  background-image: url('./bg.png');
  background-repeat: repeat-x;
  background-size:  auto 100%;
}

.imageWrapper {
  position: relative;
}
.image {
  width: 100%;
}

@media (min-width: 992px) {
  .imageWrapper {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .image {
    width: auto;
    height: 100%;
  }
}
