.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: none;
}

.line {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .main {
    display: block;
  }
}
