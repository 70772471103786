.main {
  padding: 2rem 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  text-align: left;
  max-width: 20rem;
  margin: 0 .75rem 1.5rem;
  padding: .75rem 1.5rem 1.5rem;
  background-color: #f4f4f4;
}
.cardSubheading {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 2rem;
  margin-bottom: .25rem;
  line-height: 1;
}
.cardHeading {
  min-height: 2.5rem;
  line-height: 1;
}
.cardImageWrapper {
  text-align: center;
  height: 5rem;
  margin-bottom: 1.5rem;
}
.cardImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}
.cardParagraph {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .main {
    padding: 3rem 0;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: .75rem;
    padding: 0 1rem;
  }
  .card {
    max-width: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .container {
    display: flex;
    height: 100vh;
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 4rem 2rem;
  }
  .grid {
    column-gap: 1rem;
  }
}

@media (min-width: 1200px) {
  .grid {
    column-gap: 2rem;
  }
  .card {
    padding: 1rem 2rem 2rem;
  }
  .cardParagraph {
    text-align: justify;
  }
}
