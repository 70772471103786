.imageWrapper {
  position: relative;
  padding-bottom: min(100%, 100vh); /* for aspect ratio 1:1 */
  background-color: #c5c5c5;  /* fallback */
  background-image: url('./poster.jpg');  /* video fallback */
  background-size: cover;
  background-position: center;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.textSection {
  padding: 2rem 1rem 3rem;
}
.heading {
  line-height: 1;
}
.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 24rem));
  row-gap: 2.5rem;
  column-gap: 2rem;
}
.card {
  display: flex;
  align-items: center;
}
.iconWrapper {
  padding-right: 1.25rem;
}
.icon {
  width: 3.5rem;
}

@media (min-width: 992px) {
  .main {
    display: flex;
    height: calc(100vh - 1px);  /* HACK for the scroll effect */
  }
  .imageWrapper {
    width: 67%;
    padding-bottom: 0;
  }
  .textSection {
    width: 33%;
    padding: 3rem 2rem;
  }
}

@media (min-width: 1200px) {
  .imageWrapper {
    width: 75%;
  }
  .textSection {
    width: 25%;
    padding: 3rem;
  }
}
