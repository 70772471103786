.header {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  margin-top: 0;
  padding-top: 0;
  background-image: url('./header.jpg');
  background-size: cover;
  background-position: center;
  color: white;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 15vh;
}

.titleBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font-weight: 600;
}
.subtitle {
  font-size: 1.12rem;
}

.moreLink {
  display: block;
  width: 100%;
  padding: 1rem 0 1.5rem;
  border: none;
  background: none;
  outline: none !important;
}
.down {
  height: 1rem;
}

.detailView {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.item {
  margin-left: 3vw;
  padding-right: 3vw;
}
.item:first-child {
  margin-left: 0;
}
.item:last-child {
  padding-right: 0;
}
.item:not(:last-child) {
  border-right: 1px solid white;
}
.valueWrapper {
  margin-bottom: 2vw;
}
.value {
  font-size: 5vw;
  font-weight: 600;
  white-space: nowrap;
}
.subvalue {
  font-size: 5vw;
}
.label {
  margin-bottom: 0;
  font-size: 3vw;
}

@media (min-width: 768px) {
  .item {
    margin-left: 3rem;
    padding-right: 3rem;
  }
  .item:first-child {
    margin-left: 0;
  }
  .item:last-child {
    padding-right: 0;
  }
  .valueWrapper {
    margin-bottom: .8rem;
  }
  .value, .subvalue {
    font-size: 2rem;
  }
  .label {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .detailsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  .detailView {
    margin-bottom: 0;
  }
}
