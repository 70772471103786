/* Bootstrap */
@import '~bootstrap/dist/css/bootstrap.css';
/* Barlow font */
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');

:root {
  --navbar-height: 60px;
  --navbar-height-xl: 75px;
}

html {
  scroll-behavior: smooth;  
}

body {
  color: black;
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 > b, h2 > b {
  font-weight: 600;
}

/* overriding default Bootstrap button */
.btn {
  border-width: 1.5px;
  border-radius: 1.25rem; /* rounded corners */
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: 600;
}
.btn-outline-dark {
  border-color: black !important;
  color: black;
}
.btn-outline-dark:hover {
  background-color: black;
}
.btn-outline-dark:active {
  background-color: black !important;
}
.btn-outline-light:hover {
  color: black;
}
.btn-outline-light:active {
  color: black !important;
}

/* Bootstrap carousel */
.carousel-indicators > li {
  background-color: #b8b8b8;
}

/* for the custom scroll effect */
[data-scrolleffect="true"] {
  margin-top: calc(-1 * var(--navbar-height));  /* "minus" navbar height */
  padding-top: var(--navbar-height);
}
@media (min-width: 1200px) {
  [data-scrolleffect="true"] {
    margin-top: calc(-1 * var(--navbar-height-xl));  /* "minus" navbar height XL */
    padding-top: var(--navbar-height-xl);
  }
}
