.main {
  position: relative;
  overflow-x: hidden;
}

.head {
  background-color: #c5c5c5;
}
.header {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}
.headText {
  display: none;
}
.line {
  display: none;
}

.triangle {
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-top-color: #cbc9ca;
}

.machineLinkWrapper {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .main {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .head {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 3rem;
  }
  .headText {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 2rem;
  }
  .details {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #c5c5c5;
  }
  .machine {
    position: absolute;
    top: 3rem;
    left: 40%;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .line {
    display: block;
    width: 4rem;
    border-top: 1px solid #777;
    margin-top: 3rem;
    margin-right: auto;
  }
  .triangle {
    display: none;
  }
}
