.main {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.canvasContainer, .hintOverlay, .loadingStateBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.canvasContainer > canvas {
  outline: none;
}

.hintOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, .5);
  color: white;
}
.hintText {
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.loadingStateBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topRightPanel {
  position: absolute;
  top: 1.5rem;
  right: 1.25rem;
}

@media (min-width: 1200px) {
  .topRightPanel {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
  }
}
