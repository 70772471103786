@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap);
/* Bootstrap */
/* Barlow font */

:root {
  --navbar-height: 60px;
  --navbar-height-xl: 75px;
}

html {
  scroll-behavior: smooth;  
}

body {
  color: black;
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 > b, h2 > b {
  font-weight: 600;
}

/* overriding default Bootstrap button */
.btn {
  border-width: 1.5px;
  border-radius: 1.25rem; /* rounded corners */
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: 600;
}
.btn-outline-dark {
  border-color: black !important;
  color: black;
}
.btn-outline-dark:hover {
  background-color: black;
}
.btn-outline-dark:active {
  background-color: black !important;
}
.btn-outline-light:hover {
  color: black;
}
.btn-outline-light:active {
  color: black !important;
}

/* Bootstrap carousel */
.carousel-indicators > li {
  background-color: #b8b8b8;
}

/* for the custom scroll effect */
[data-scrolleffect="true"] {
  margin-top: calc(-1 * 60px);
  margin-top: calc(-1 * var(--navbar-height));  /* "minus" navbar height */
  padding-top: 60px;
  padding-top: var(--navbar-height);
}
@media (min-width: 1200px) {
  [data-scrolleffect="true"] {
    margin-top: calc(-1 * 75px);
    margin-top: calc(-1 * var(--navbar-height-xl));  /* "minus" navbar height XL */
    padding-top: 75px;
    padding-top: var(--navbar-height-xl);
  }
}

.navbar_navbar__2wbBl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--navbar-height);
  padding: 0 1rem;
}
.navbar_navbar__2wbBl.navbar_navbarTransparent__3_LnK {
  background-color: transparent;
  color: white !important;
  transition: 400ms ease;
}
.navbar_navbar__2wbBl.navbar_navbarWhite__2vQTo {
  box-shadow: 0 0 4px gray;
  background-color: white;
  color: black !important;
  transition: 400ms ease;
}

.navbar_logoLink__NIMuv {
  padding-bottom: .25rem;
}
.navbar_logo__7aEvj {
  width: 10rem;
}

.navbar_menuButton__3YjXy {
  border: none;
  background: none;
}

@media (min-width: 992px) {
  .navbar_menuButton__3YjXy {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar_navbar__2wbBl {
    height: var(--navbar-height-xl);
    padding: 0 3rem;
  }
}

.language-switch_main__2qYu2 {
  font-size: 1.25rem;
}

.language-switch_separator__1K0sx {
  color: inherit;
}

.language-switch_link__1h80m {
  color: inherit !important;
  text-decoration: none !important;
  font-weight: bold;
}

@media (min-width: 992px) {
  .language-switch_main__2qYu2 {
    font-size: 1rem;
  }
}

.mobile-nav_container__11Bh4 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 2rem 2rem 0;
}

.mobile-nav_logo__2kil9 {
  width: 10rem;
}
.mobile-nav_closeButton__1WZwb {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  margin-top: -.25rem;
  margin-right: -.25rem;
  padding: 0;
  background: none;
}

.mobile-nav_main__2j2SX {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.mobile-nav_footer__3MLOE {
  margin-bottom: 2rem;
}

/* OVERRIDING BOOTSTRAP NAVS */

.nav-item {
  position: relative;
}
.nav-item:not(:last-child)::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.5rem;
  border-top: 1px solid white;
  content: '';
}

.nav-link {
  text-align: right;
  padding: 1rem 0;
  color: inherit !important;
  font-size: 1.25rem;
  font-weight: 500;
}
.nav-link.active {
  font-weight: 700;
}

@media (min-width: 992px) {
  .nav-item::after {
    display: none;
  }
  .nav-link {
    padding: .25rem 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .nav-link {
    padding: .25rem 1.5rem;
  }
}

.contact-data_main__1MK3e {
  color: inherit;
  font-weight: 600;
}

.header_header__1lzmy {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  margin-top: 0;
  padding-top: 0;
  background-image: url(/static/media/header.cc62e024.jpg);
  background-size: cover;
  background-position: center;
  color: white;
}

.header_video__1-fOX {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header_overlay__3PtlH {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 15vh;
}

.header_titleBlock__3KBPt {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_title__1Lw2g {
  font-weight: 600;
}
.header_subtitle__2aZUw {
  font-size: 1.12rem;
}

.header_moreLink__3iS7F {
  display: block;
  width: 100%;
  padding: 1rem 0 1.5rem;
  border: none;
  background: none;
  outline: none !important;
}
.header_down__3vuxP {
  height: 1rem;
}

.header_detailView__19oCB {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.header_item__388Rc {
  margin-left: 3vw;
  padding-right: 3vw;
}
.header_item__388Rc:first-child {
  margin-left: 0;
}
.header_item__388Rc:last-child {
  padding-right: 0;
}
.header_item__388Rc:not(:last-child) {
  border-right: 1px solid white;
}
.header_valueWrapper__2oVkK {
  margin-bottom: 2vw;
}
.header_value__3quqz {
  font-size: 5vw;
  font-weight: 600;
  white-space: nowrap;
}
.header_subvalue__3rgmV {
  font-size: 5vw;
}
.header_label__1fYoP {
  margin-bottom: 0;
  font-size: 3vw;
}

@media (min-width: 768px) {
  .header_item__388Rc {
    margin-left: 3rem;
    padding-right: 3rem;
  }
  .header_item__388Rc:first-child {
    margin-left: 0;
  }
  .header_item__388Rc:last-child {
    padding-right: 0;
  }
  .header_valueWrapper__2oVkK {
    margin-bottom: .8rem;
  }
  .header_value__3quqz, .header_subvalue__3rgmV {
    font-size: 2rem;
  }
  .header_label__1fYoP {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .header_detailsWrapper__3Tu6I {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
  .header_detailView__19oCB {
    margin-bottom: 0;
  }
}

.contact-form_main__3jNpD {
  color: black;
}

@media (min-width: 768px) {
  .contact-form_main__3jNpD {
    width: 25rem;
  }
}

.features_main__LnJnG {
  position: relative;
  overflow-x: hidden;
}

.features_head__27MMu {
  background-color: #c5c5c5;
}
.features_header__3maSQ {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}
.features_headText__2v7dx {
  display: none;
}
.features_line__1fXxc {
  display: none;
}

.features_triangle__2lGJX {
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-top-color: #cbc9ca;
}

.features_machineLinkWrapper__1ORY7 {
  position: relative;
  z-index: 2;
}

@media (min-width: 992px) {
  .features_main__LnJnG {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .features_head__27MMu {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding-bottom: 3rem;
  }
  .features_headText__2v7dx {
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 2rem;
  }
  .features_details__2xaQm {
    flex: 1 1;
    display: flex;
    align-items: center;
    background-color: #c5c5c5;
  }
  .features_machine__p4-eG {
    position: absolute;
    top: 3rem;
    left: 40%;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .features_line__1fXxc {
    display: block;
    width: 4rem;
    border-top: 1px solid #777;
    margin-top: 3rem;
    margin-right: auto;
  }
  .features_triangle__2lGJX {
    display: none;
  }
}

.model_container__1eJ3R {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.model_main__1_0Gh {
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAHGCAIAAADR2s1lAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5AgeEio4mXyb8QAAAU1JREFUSMfVlkuOhDAMRF9Zvv+pOFdqFgSRCMgEhm5pVlGC7fKnbKNlWYAAvnmk7U+Yth1/N217u71qrLcp6fCY/eMHC/Aq0G5M0re59GpCXiXmU2MzervIkWAjkYFehe3Rz28DkdM2vIxowvlHYZ7CTuTs1Sl1F29roKe9+QTvH/XmPclmEnXq+fvtqch2RMTlt7NjZGUocvfbKIaJ9ByJKeneJjnQOwVIAUIEEiZYM2h16qUAoQdNObHRm5lVHTyTzOnHkKF4pmF7vIiQlFvstk0IabBhbaeFIWXwlPOllJqXfl4fbs1iEGRUYzKQxWUrY0Uf8Gwvf65hJIj2saUGFM1RqnnsJp8v9nuaMIRWhbJxsHNw5+fVtCmz5EtTajURYQsrJNVc9zEcy/FwH+XVX2XfhhWhdWJPgRR4NYYxuWatBdKe5L387lIgfgA65wiJ0guy0wAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  background-size:  auto 100%;
}

.model_imageWrapper__2wiWP {
  position: relative;
}
.model_image__2aLH2 {
  width: 100%;
}

@media (min-width: 992px) {
  .model_imageWrapper__2wiWP {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .model_image__2aLH2 {
    width: auto;
    height: 100%;
  }
}

.point_container__14rMn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
}

.point_button__e4yAS {
  position: absolute;
  top: -.75rem;
  left: -.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  padding: 0;
  background: none;
  outline: none !important;
}

.point_icon__c-9SL {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: white;
  -webkit-animation: point_pulse-white__3aGAD 2s infinite;
          animation: point_pulse-white__3aGAD 2s infinite;
}

@-webkit-keyframes point_pulse-white__3aGAD {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes point_pulse-white__3aGAD {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media (min-width: 768px) {
  .point_icon__c-9SL {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.line_main__1d7ZF {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: none;
}

.line_line__13v8u {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .line_main__1d7ZF {
    display: block;
  }
}

.detail_main__1F-HE {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  padding: 0;
}

.detail_imageWrapper__17d4c {
  flex: 1 1;
  max-height: 55%;
}

.detail_image__3v4m0 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 992px) {
  .detail_image__3v4m0 {
    width: 80%;
    object-position: left center;
  }
}

/* ASSISTANCE */

.assistance_card__1k3-- {
  display: flex;
  align-items: center;
}

.assistance_iconWrapper__1AaAi {
  padding-right: 1.25rem;
}
.assistance_icon__7XoEI {
  width: 4rem;
}

@media (min-width: 992px) {
  .assistance_container__2DSKv {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .assistance_header__nyrQr {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-right: 1px solid black;
    padding-right: 3rem;
  }
}


/* FORMATION */

.assistance_formation__1LulM {
  position: relative;
  flex: 1 1;
  overflow: hidden;
}

.assistance_image__3XuWu {
  width: 145%;
}

.assistance_video__1aPcT {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
}

@media (min-width: 768px) {
  .assistance_image__3XuWu {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .assistance_image__3XuWu {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }
}

.layout_imageWrapper__38YhF {
  position: relative;
  padding-bottom: min(100%, 100vh); /* for aspect ratio 1:1 */
  background-color: #c5c5c5;  /* fallback */
  background-image: url(/static/media/rental.9e57a4c2.jpg);  /* video fallback */
  background-size: cover;
  background-position: center;
}
.layout_video__1q3WA {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.layout_textSection__2eqi3 {
  padding: 2rem 1rem 3rem;
}
.layout_cardGrid__2pHLr {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 24rem));
  grid-row-gap: 3rem;
  row-gap: 3rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}
.layout_card__28nCh {
  display: flex;
  align-items: center;
}
.layout_iconWrapper__1foYN {
  padding-right: 1.25rem;
}
.layout_icon__3TqzR {
  width: 4rem;
}

@media (min-width: 992px) {
  .layout_main__2xLS_ {
    display: flex;
    height: 100vh;
  }
  .layout_imageWrapper__38YhF {
    width: 67%;
    padding-bottom: 0;
  }
  .layout_textSection__2eqi3 {
    width: 33%;
    padding: 3rem 2rem;
  }
}

@media (min-width: 1200px) {
  .layout_imageWrapper__38YhF {
    width: 75%;
  }
  .layout_textSection__2eqi3 {
    width: 25%;
    padding: 3rem;
  }
}

.rental_tr__YgZU5:not(:first-child) {
  border-top: 1px solid black;
}
.rental_th__2R4qN, .rental_td__1dZX4 {
  padding: 1.5rem .5rem;
}
.rental_th__2R4qN {
  font-weight: 500;
}
.rental_td__1dZX4 {
  text-align: right;
}
.rental_request__2ceGO {
  font-weight: 600;
}

.cropchoice_header__1TNrQ {
  margin-bottom: 1.5rem;
}

.cropchoice_textSection__1fuqo {
  padding: 2rem 1rem;
}
.cropchoice_buttonGrid__1pP_g {
  display: grid;
  grid-template-columns: repeat(auto-fill, 3rem);
  grid-gap: .5rem;
  gap: .5rem;
  max-width: 24rem;
}

.cropchoice_videoSection__1wm1L {
  position: relative;
  height: 0;
  padding-bottom: min(100%, 100vh);
  background-color: #c5c5c5;  /* fallback */
}
.cropchoice_video__37z0v {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  visibility: hidden;
}
.cropchoice_video__37z0v.cropchoice_active__1ImF6 {
  visibility: visible;
}

@media (min-width: 600px) {
  .cropchoice_buttonGrid__1pP_g {
    grid-template-columns: repeat(10, 1fr);
    max-width: none;
  }
}

@media (min-width: 768px) {
  .cropchoice_buttonGrid__1pP_g {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .cropchoice_videoSection__1wm1L {
    height: 100vh;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .cropchoice_main__nIANc {
    display: flex;
    height: 100vh;
  }
  .cropchoice_header__1TNrQ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 7rem;
    margin-bottom: 0;
  }
  .cropchoice_textSection__1fuqo {
    width: 16rem;
    padding: 1rem 2rem 0;
  }
  .cropchoice_buttonGrid__1pP_g {
    grid-template-columns: repeat(2, min(11.5vh, 45%));
  }
  .cropchoice_videoSection__1wm1L {
    flex: 1 1;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .cropchoice_textSection__1fuqo {
    width: 20rem;
    padding: 1rem 3rem 0;
  }
  .cropchoice_videoSection__1wm1L {
    width: 75%;
  }
}

.button_buttonWrapper__3NVAH {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.button_button__2EfP6 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2);
  border: none;
  background: none;
  font-size: .9rem;
  line-height: 1;
  outline: none !important;
}
.button_button__2EfP6:hover {
  background-color: #e5e5e5;
}
.button_button__2EfP6.button_active__18cyZ {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2) inset;
  background-color: #d3d3d3;
}

.button_icon__2v5pl {
  width: 2rem;
  margin-left: auto;
  margin-right: auto;
}
.button_cropTitle__2dZqX {
  display: none;
}

@media (min-width: 768px) {
  .button_buttonGrid__HERU_ {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .button_videoSection__2fzMT {
    height: 100vh;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .button_cropTitle__2dZqX {
    display: block;
    margin: 5px;
    text-align: center;
  }
  .button_icon__2v5pl {
    width: 45%;
    max-width: 2rem;
  }
}

.benefits_imageWrapper__384ZD {
  position: relative;
  padding-bottom: min(100%, 100vh); /* for aspect ratio 1:1 */
  background-color: #c5c5c5;  /* fallback */
  background-image: url(/static/media/poster.4b066478.jpg);  /* video fallback */
  background-size: cover;
  background-position: center;
}
.benefits_video__3eLZU {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.benefits_textSection__ANUbT {
  padding: 2rem 1rem 3rem;
}
.benefits_heading__2fHUB {
  line-height: 1;
}
.benefits_cardGrid__LTaky {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 24rem));
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}
.benefits_card__mWYBf {
  display: flex;
  align-items: center;
}
.benefits_iconWrapper__2pJq2 {
  padding-right: 1.25rem;
}
.benefits_icon__1CFg3 {
  width: 3.5rem;
}

@media (min-width: 992px) {
  .benefits_main__1JT2z {
    display: flex;
    height: calc(100vh - 1px);  /* HACK for the scroll effect */
  }
  .benefits_imageWrapper__384ZD {
    width: 67%;
    padding-bottom: 0;
  }
  .benefits_textSection__ANUbT {
    width: 33%;
    padding: 3rem 2rem;
  }
}

@media (min-width: 1200px) {
  .benefits_imageWrapper__384ZD {
    width: 75%;
  }
  .benefits_textSection__ANUbT {
    width: 25%;
    padding: 3rem;
  }
}

.factory_imageWrapper__2wS-k {
  position: relative;
  padding-bottom: min(100%, 100vh); /* for aspect ratio 1:1 */
  background-color: #c5c5c5;  /* fallback */
  background-image: url(/static/media/poster.475eec07.jpg);  /* video fallback */
  background-size: cover;
  background-position: center;
}
.factory_video__rz3uj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.factory_textSection__17-xB {
  padding: 2rem 1rem 3rem;
}
.factory_cardGrid__3XnZC {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 24rem));
  grid-row-gap: 3rem;
  row-gap: 3rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}
.factory_card__1J6Ap {
  display: flex;
  align-items: center;
}
.factory_iconWrapper__2mWPU {
  padding-right: 1.25rem;
}
.factory_icon__2gRXJ {
  width: 4rem;
}

@media (min-width: 992px) {
  .factory_main__3Qf48 {
    display: flex;
    height: 100vh;
  }
  .factory_imageWrapper__2wS-k {
    width: 67%;
    padding-bottom: 0;
  }
  .factory_textSection__17-xB {
    width: 33%;
    padding: 3rem 2rem;
  }
}

@media (min-width: 1200px) {
  .factory_imageWrapper__2wS-k {
    width: 75%;
  }
  .factory_textSection__17-xB {
    width: 25%;
    padding: 3rem;
  }
}

.arguments_main__K2Nvi {
  padding: 2rem 0;
}

.arguments_grid__34R__ {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.arguments_card__1DcED {
  text-align: left;
  max-width: 20rem;
  margin: 0 .75rem 1.5rem;
  padding: .75rem 1.5rem 1.5rem;
  background-color: #f4f4f4;
}
.arguments_cardSubheading__18qlg {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 2rem;
  margin-bottom: .25rem;
  line-height: 1;
}
.arguments_cardHeading__11imk {
  min-height: 2.5rem;
  line-height: 1;
}
.arguments_cardImageWrapper__2TOs_ {
  text-align: center;
  height: 5rem;
  margin-bottom: 1.5rem;
}
.arguments_cardImage__1G1PI {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}
.arguments_cardParagraph__3jCHC {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .arguments_main__K2Nvi {
    padding: 3rem 0;
  }
  .arguments_grid__34R__ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: .75rem;
    -webkit-column-gap: .75rem;
            column-gap: .75rem;
    padding: 0 1rem;
  }
  .arguments_card__1DcED {
    max-width: none;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .arguments_container__3rURD {
    display: flex;
    height: 100vh;
  }
  .arguments_main__K2Nvi {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 4rem 2rem;
  }
  .arguments_grid__34R__ {
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }
}

@media (min-width: 1200px) {
  .arguments_grid__34R__ {
    grid-column-gap: 2rem;
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
  .arguments_card__1DcED {
    padding: 1rem 2rem 2rem;
  }
  .arguments_cardParagraph__3jCHC {
    text-align: justify;
  }
}

.footer_footer__2oW47 {
  padding: 1.25rem .25rem;
  background-color: black;
  color: white;
}

.footer_footer__2oW47 strong {
  font-weight: 600;
}

.footer_footer__2oW47 a {
  color: white;
  text-decoration: underline;
}

.footer_brandLink__3PRbk {
  text-decoration: none !important;
}

.machine_main__32Qbq {
  position: relative;
  flex: 1 1;
  overflow: hidden;
}

.machine_canvasContainer__1BLcX, .machine_hintOverlay__3rNYV, .machine_loadingStateBlock__2LtZ2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.machine_canvasContainer__1BLcX > canvas {
  outline: none;
}

.machine_hintOverlay__3rNYV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, .5);
  color: white;
}
.machine_hintText__2bzSG {
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.machine_loadingStateBlock__2LtZ2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.machine_topRightPanel__19piC {
  position: absolute;
  top: 1.5rem;
  right: 1.25rem;
}

@media (min-width: 1200px) {
  .machine_topRightPanel__19piC {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
  }
}

