.header {
  margin-bottom: 1.5rem;
}

.textSection {
  padding: 2rem 1rem;
}
.buttonGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 3rem);
  gap: .5rem;
  max-width: 24rem;
}

.videoSection {
  position: relative;
  height: 0;
  padding-bottom: min(100%, 100vh);
  background-color: #c5c5c5;  /* fallback */
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  visibility: hidden;
}
.video.active {
  visibility: visible;
}

@media (min-width: 600px) {
  .buttonGrid {
    grid-template-columns: repeat(10, 1fr);
    max-width: none;
  }
}

@media (min-width: 768px) {
  .buttonGrid {
    gap: 1rem;
  }
  .videoSection {
    height: 100vh;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .main {
    display: flex;
    height: 100vh;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 7rem;
    margin-bottom: 0;
  }
  .textSection {
    width: 16rem;
    padding: 1rem 2rem 0;
  }
  .buttonGrid {
    grid-template-columns: repeat(2, min(11.5vh, 45%));
  }
  .videoSection {
    flex: 1;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .textSection {
    width: 20rem;
    padding: 1rem 3rem 0;
  }
  .videoSection {
    width: 75%;
  }
}
