.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 2rem 2rem 0;
}

.logo {
  width: 10rem;
}
.closeButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  margin-top: -.25rem;
  margin-right: -.25rem;
  padding: 0;
  background: none;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}

.footer {
  margin-bottom: 2rem;
}
