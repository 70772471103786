.container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
}

.button {
  position: absolute;
  top: -.75rem;
  left: -.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  padding: 0;
  background: none;
  outline: none !important;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: white;
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media (min-width: 768px) {
  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
