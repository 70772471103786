.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--navbar-height);
  padding: 0 1rem;
}
.navbar.navbarTransparent {
  background-color: transparent;
  color: white !important;
  transition: 400ms ease;
}
.navbar.navbarWhite {
  box-shadow: 0 0 4px gray;
  background-color: white;
  color: black !important;
  transition: 400ms ease;
}

.logoLink {
  padding-bottom: .25rem;
}
.logo {
  width: 10rem;
}

.menuButton {
  border: none;
  background: none;
}

@media (min-width: 992px) {
  .menuButton {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar {
    height: var(--navbar-height-xl);
    padding: 0 3rem;
  }
}
