/* OVERRIDING BOOTSTRAP NAVS */

.nav-item {
  position: relative;
}
.nav-item:not(:last-child)::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1.5rem;
  border-top: 1px solid white;
  content: '';
}

.nav-link {
  text-align: right;
  padding: 1rem 0;
  color: inherit !important;
  font-size: 1.25rem;
  font-weight: 500;
}
.nav-link.active {
  font-weight: 700;
}

@media (min-width: 992px) {
  .nav-item::after {
    display: none;
  }
  .nav-link {
    padding: .25rem 1rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .nav-link {
    padding: .25rem 1.5rem;
  }
}
