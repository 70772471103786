.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  padding: 0;
}

.imageWrapper {
  flex: 1;
  max-height: 55%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 992px) {
  .image {
    width: 80%;
    object-position: left center;
  }
}
