.tr:not(:first-child) {
  border-top: 1px solid black;
}
.th, .td {
  padding: 1.5rem .5rem;
}
.th {
  font-weight: 500;
}
.td {
  text-align: right;
}
.request {
  font-weight: 600;
}
