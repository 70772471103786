.main {
  font-size: 1.25rem;
}

.separator {
  color: inherit;
}

.link {
  color: inherit !important;
  text-decoration: none !important;
  font-weight: bold;
}

@media (min-width: 992px) {
  .main {
    font-size: 1rem;
  }
}
