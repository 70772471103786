/* ASSISTANCE */

.card {
  display: flex;
  align-items: center;
}

.iconWrapper {
  padding-right: 1.25rem;
}
.icon {
  width: 4rem;
}

@media (min-width: 992px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .header {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    border-right: 1px solid black;
    padding-right: 3rem;
  }
}


/* FORMATION */

.formation {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.image {
  width: 145%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
}

@media (min-width: 768px) {
  .image {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
  }
}
